<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <p>
          <ValidationProvider v-slot="{errors, classes}"
                              :name="label"
                              :rules="rules">
            <sb-input :label="label"
                      :error="classes.invalid">
              <v-autocomplete v-model="selectedValue"
                              :loading="$wait.is('searchVehiclesLoading')"
                              :items="vehicleResults"
                              :search-input.sync="searchVehicleString"
                              :filter="customSearchVehicleFilter"
                              :readonly="readonly"
                              :clearable="!readonly"
                              :error-messages="errors"
                              :error="classes.invalid"
                              item-text="registrationNumber"
                              return-object
                              cache-items
                              hide-details
                              hide-selected
                              dense
                              outlined
                              @input="vehicleSelectedChange">
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for Vehicles by
                      <strong>Registration Number</strong>
                      or
                      <strong>VIN Number</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{item}">
                  <span>{{ item.registrationNumber }} / {{ item.vehicleIdentificationNumber }}</span>
                </template>

                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.registrationNumber" />
                    <v-list-item-subtitle v-text="item.vehicleIdentificationNumber" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <span class="field-details"
                    :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
            </sb-input>
          </validationprovider>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';

export default {
  components: {
    ValidationProvider,
  },
  mixins: [inputBase],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },
    label: {
      type: String,
      default: 'Vehicle Registration Number / VIN Number',
    },
    vehicleDefaultValue: {
      type: Object,
      default: () => undefined,
    },
  },

  data() {
    return {
      searchVehicleString: null,
      selectedVehicleResult: null,
    };
  },

  computed: {
    ...mapGetters({
      vehicleResults: 'vehicles/vehicleResults',
    }),
    selectedValue: {
      get() {
        if (this.vehicleDefaultValue) {
          return {
            registrationNumber: this.vehicleDefaultValue.registrationNumber,
            vehicleIdentificationNumber: this.vehicleDefaultValue.vehicleIdentificationNumber,
          };
        }
        return this.selectedVehicleResult;
      },
      set(newValue) {
        this.selectedVehicleResult = newValue;
      },
    },
  },

  watch: {
    searchVehicleString(value) {
      // eslint-disable-next-line
      value && value !== this.selectedVehicleResult && this.searchVehicles(value);
    },
    vehicleDefaultValue: {
      handler(value) {
        if (value) {
          this.searchVehicleString = value.registrationNumber;
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('vehicles', ['searchVehicles', 'getVehicleDetails']),

    ...mapWaitingActions('vehicles', {
      searchVehicles: 'searchVehiclesLoading',
    }),

    customSearchVehicleFilter(item, queryText) {
      const registrationNumber = item.registrationNumber ? item.registrationNumber.toLowerCase() : '';
      const vin = item.vehicleIdentificationNumber ? item.vehicleIdentificationNumber.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return registrationNumber.includes(searchText) || vin.includes(searchText);
    },

    async vehicleSelectedChange() {
      const vehicleDetails = await this.getVehicleDetails(this.selectedVehicleResult.registrationNumber);
      this.$emit('vehicle', this.selectedVehicleResult);
      this.$emit('vehicle-details', vehicleDetails);
    },
  },
};
</script>
