<template>
  <sb-card :loading="$wait.is('getAllAttachmentsLoading')"
           min-width="100%">
    <template slot="title">
      <v-card-title>
        <sb-title title="Supporting Documents" />
        <v-spacer />
      </v-card-title>
    </template>
    <sb-document-list :attachments="attachments"
                      :is-loading="$wait.is('getAllAttachmentsLoading')"
                      :is-downloading="$wait.is('getAttachmentLoading')"
                      @download-document="downloadAttachment" />
  </sb-card>
</template>
<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import SbDocumentList from '@/components/core/SbDocumentList';
import { EventBus } from '@/utils/event-bus';

export default {
  components: {
    SbDocumentList,
  },
  props: {
    serviceRequestId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      attachments: [],
    };
  },
  watch: {
    serviceRequestId: {
      handler(value) {
        if (value) {
          this.getAttachments();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    EventBus.$on('upload-completed', async () => {
      await this.getAttachments();
    });
  },
  methods: {
    ...mapActions('attachments', ['getAttachment', 'getAllAttachments']),
    ...mapWaitingActions('attachments', {
      getAllAttachments: 'getAllAttachmentsLoading',
      getAttachment: 'getAttachmentLoading',
    }),

    async downloadAttachment(attachmentId) {
      await this.getAttachment(attachmentId);
    },

    async getAttachments() {
      const payload = { serviceRequestId: this.serviceRequestId };
      this.getAllAttachments(payload).then((response) => {
        if (response) {
          this.attachments = response.documents;
        }
      });
    },
  },
};
</script>
