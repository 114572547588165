<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <sb-input :label="label"
                  :value="currentValue">
          <v-autocomplete v-model="selectedItemResult"
                          :loading="searchJobCardItemsLoading"
                          :items="jobCardItemResults"
                          :search-input.sync="searchItemString"
                          :filter="customSearchItemFilter"
                          item-text="id"
                          item-value="description"
                          value="id"
                          return-object
                          cache-items
                          hide-details
                          hide-selected
                          dense
                          clearable
                          outlined
                          @click:clear="clearItemResult"
                          @input="updateItemResult">
            <template #no-data>
              <v-list-item>
                <v-list-item-title>
                  Search for {{ itemType | startCase }} by
                  <strong>Part Number / Operation Id</strong>
                </v-list-item-title>
              </v-list-item>
            </template>

            <template #item="{item}">
              <v-list-item-content v-if="item">
                <v-list-item-title>
                  <v-row justify="space-between"
                         class="mx-0">
                    <div>{{ item.id }}</div>
                    <div>
                      {{ item.price | currency }}
                    </div>
                  </v-row>
                </v-list-item-title>
                <v-list-item-subtitle v-text="item.description" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </sb-input>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    merchantId: {
      type: String,
      default: '10049223',
    },
    itemType: {
      type: String,
      default: 'OTHER',
    },
    reference: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      currentValue: null,
      searchItemString: null,
      selectedItemResult: null,
      jobCardItemResults: [],
      searchJobCardItemsLoading: false,
    };
  },

  watch: {
    async searchItemString(value) {
      const payload = {
        merchantId: this.merchantId,
        itemType: this.itemType,
        searchString: value,
      };

      this.searchJobCardItemsLoading = true;
      this.jobCardItemResults = await this.searchJobCardItems(payload);
      this.searchJobCardItemsLoading = false;
    },

    async currentValue(value) {
      const payload = {
        merchantId: this.merchantId,
        itemType: this.itemType,
        searchString: value,
      };

      this.searchJobCardItemsLoading = true;
      this.jobCardItemResults = await this.searchJobCardItems(payload);
      this.selectedItemResult = this.jobCardItemResults.find(item => item.id === this.currentValue);
      this.searchJobCardItemsLoading = false;
    },
  },

  mounted() {
    if (this.$attrs['current-value']) {
      this.currentValue = this.$attrs['current-value'];
    }
  },

  methods: {
    ...mapActions('merchants', ['searchJobCardItems']),

    customSearchItemFilter(item, queryText) {
      if (item) {
        const id = item.id ? item.id.toLowerCase() : '';
        const description = item.description ? item.description.toLowerCase() : '';
        const searchText = queryText.toLowerCase();

        return id.includes(searchText) || description.includes(searchText);
      }

      return false;
    },

    async updateItemResult(item) {
      const { reference } = this;
      reference.selectedItem = item;
      this.$emit('item-update', reference);
    },

    async clearItemResult() {
      const { reference } = this;
      this.$emit('item-clear', reference);
    },
  },
};
</script>
