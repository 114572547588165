<template>
  <v-container class="sb-max px-0">
    <v-row v-if="value.icon"
           justify="center"
           class="pt-3">
      <v-icon :size="150"
              :color="value.iconColor || 'primary'">
        {{ value.icon }}
      </v-icon>
    </v-row>

    <v-row v-else
           justify="center">
      <v-row justify="center">
        <v-img :src="value.img"
               max-width="180"
               alt="SB_Badge" />
      </v-row>
    </v-row>

    <v-row v-if="value.title"
           justify="center"
           class="text-h2 my-5">
      <span>{{ value.title }}</span>
    </v-row>

    <v-row justify="center">
      <div class="text-caption">
        {{ value.message }}
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
