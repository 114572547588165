<template>
  <v-container>
    <v-row class="sb-grey--text my-3">
      <v-col cols="10"
             class="py-1 text-right">
        <span class="font-weight-bold">Total Net Excl VAT</span>
      </v-col>
      <v-col cols="2"
             class="py-1">
        <span>{{ totals.total | currency }}</span>
      </v-col>

      <v-col cols="10"
             class="py-1 text-right">
        <span class="font-weight-bold">Total VAT (15%)</span>
      </v-col>
      <v-col cols="2"
             class="py-1">
        <span>{{ totals.vat | currency }}</span>
      </v-col>

      <v-col cols="10"
             class="py-1 text-right">
        <span class="font-weight-bold">Total Incl VAT</span>
      </v-col>
      <v-col cols="2"
             class="py-1">
        <span>{{ totals.grandTotal | currency }}</span>
      </v-col>
    </v-row>

    <v-row class="sb-grey--text" />
  </v-container>
</template>

<script>
export default {
  props: {
    totals: {
      type: Object,
      default: () => {},
    },
  },

};
</script>
