<template>
  <v-row>
    <v-col class="pt-0">
      <sb-card :title="title"
               :loading="serviceRequestLoading"
               min-width="100%">
        <v-container>
          <sb-vehicle-search v-if="!readOnly"
                             rules="selected|required"
                             @vehicle="selectedVehicle" />
          <sb-vehicle-details :vehicle-details="readOnly ? serviceRequest.vehicle : undefined"
                              :registration-number="registrationNumber"
                              :readonly="readOnly"
                              @vehicle-update="updateVehicle" />
          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <sb-numeric-field :value="serviceRequest.vehicleDetails.odometerReading"
                                :readonly="readOnly && !canUpdateAuthorisation"
                                label="Odometer Reading"
                                rules="required|odomin"
                                placeholder="100000"
                                @input="updateVehicleOdometerReading" />
            </v-col>
          </v-row>
        </v-container>
      </sb-card>
    </v-col>
  </v-row>
</template>

<script>
import SbVehicleSearch from '@/components/vehicle/SbVehicleSearch';
import SbVehicleDetails from '@/components/vehicle/SbVehicleDetails';
import { extend } from 'vee-validate';

extend('odomin', {
  validate(value) {
    const min = Number(value);
    return min > 1;
  },
  message: 'Odometer reading is required',
});

export default {
  components: {
    SbVehicleSearch,
    SbVehicleDetails,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    serviceRequestLoading: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    canUpdateAuthorisation: {
      type: Boolean,
      default: false,
    },
    serviceRequest: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      vehicleDetails: null,
      registrationNumber: null,
    };
  },
  methods: {
    updateVehicle(value) {
      this.$emit('vehicle-update', value);
    },

    updateVehicleOdometerReading(value) {
      this.$emit('vehicle-odometer-update', value);
    },

    selectedVehicle(value) {
      this.registrationNumber = value ? value.registrationNumber : null;
    },
  },
};
</script>
