<template>
  <ValidationObserver v-slot="{invalid}">
    <sb-card :loading="isLoading"
             min-width="100%">
      <template slot="title">
        <v-card-title>
          <sb-title title="Mandate Override" />
          <v-spacer />
        </v-card-title>
      </template>
      <sb-text-area v-model.trim="mandateOverrideComment"
                    rules="required|textArea"
                    label="Mandate Override Comment"
                    ref-name="mandateOverrideComment"
                    @keydown.space="preventLeadingSpace" />
      <v-row align="start">
        <v-col cols="12"
               sm="6">
          <sb-file-upload-field v-model="documents"
                                rules="document"
                                placeholder="Supporting Documents"
                                :file-types="'image/*,.pdf'"
                                :is-multiple-file-selection="true"
                                label="Supporting Documents" />
        </v-col>
      </v-row>
      <template slot="actions">
        <v-spacer />
        <v-btn color="error"
               large
               depressed
               :disabled="$wait.any || invalid"
               :loading="isAction"
               @click="declineMandateRequest">
          Decline
        </v-btn>

        <v-btn color="success"
               large
               depressed
               class="ml-5"
               :disabled="$wait.any || invalid"
               :loading="isAction"
               @click="overrideMandateRequest">
          Override/Approve
        </v-btn>
      </template>
    </sb-card>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver, extend } from 'vee-validate';

extend('document', {
  validate(value) {
    return !value.some((file) => file.size > 5_242_880);
  },
  message: 'Document size should be less than 5 MB!',
});

let errorMessage = '';
extend('textArea', {
  validate(value) {
    if (value && value.length < 20) {
      errorMessage = 'must be greater than 20 characters';
    } else {
      return true;
    }
    return false;
  },
  message: (field) => `${field} ${errorMessage}`,
});
export default {
  components: {
    ValidationObserver,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mandateOverrideComment: '',
      documents: [],
    };
  },
  methods: {
    declineMandateRequest() {
      this.$emit('decline', { comment: this.mandateOverrideComment, file: this.documents });
    },

    overrideMandateRequest() {
      this.$emit('override', { comment: this.mandateOverrideComment, file: this.documents });
    },

    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      this.mandateOverrideComment = e.target.value.replace(/\s+/g, ' ');
    },
  },
};
</script>
