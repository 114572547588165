<template>
  <v-dialog v-model="displayAuthorisationResponseDialog"
            max-width="650"
            persistent>
    <sb-card :title="authorisationTitle">
      <v-card-text>
        <template v-if="!authorisationNumber && !authorisationError">
          <v-row justify="center">
            <sb-loader class="my-10" />
          </v-row>
          <v-row justify="center">
            <div class="text-caption mb-3 text-center">
              Waiting for Authorisation response for <br>
              Service Request Number {{ serviceRequestNumber }}...
            </div>
          </v-row>
        </template>

        <v-row v-else
               justify="center">
          <v-col v-if="authorisationNumber"
                 cols="12"
                 sm="8"
                 class="d-flex justify-center">
            <v-img src="/img/animations/form-complete.gif"
                   max-width="280" />
          </v-col>
          <v-col v-if="authorisationError"
                 cols="12"
                 sm="8"
                 class="d-flex justify-center">
            <v-icon color="error"
                    :size="280">
              mdi-close-thick
            </v-icon>
          </v-col>
          <v-col cols="12"
                 sm="8"
                 class="text-center mt-5">
            <div v-if="authorisationNumber">
              <div class="text-caption mb-3">
                Your Authorisation Number
              </div>
              <div class="big-number">
                {{ authorisationNumber }}
              </div>
            </div>
            <div v-else>
              <div class="text-caption mb-3">
                <div class="mb-3">
                  {{ authorisationError }}
                </div>
                <div class="text-body-1">
                  Technical error, unable to provide Authorisation.
                  <br>Please contact Standard Bank Call Centre on 0860 222 668.
                </div>
                <div class="text-body-1">
                  Service Request Number: {{ serviceRequestNumber }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <template slot="actions">
        <v-spacer />
        <v-btn v-if="authorisationNumber || authorisationError"
               color="secondary"
               @click="closeAuthDialog">
          Close
        </v-btn>
      </template>
    </sb-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

export default {
  props: {
    serviceRequestNumber: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      authorisationTitle: '',
      authorisationNumber: '',
      authorisationError: '',
      attempts: 0,
      maxAttempts: 10,
      interval: 1000,
    };
  },

  computed: {
    displayAuthorisationResponseDialog: {
      get() {
        return this.$store.state.session.displayAuthorisationResponseDialog;
      },
      set(value) {
        this.$store.commit('session/displayAuthorisationResponseDialog', value);
      },
    },
  },

  watch: {
    async displayAuthorisationResponseDialog(value) {
      if (value) {
        this.authorisationTitle = 'Requesting Authorisation';
        this.authorisationNumber = null;
        this.authorisationError = null;

        await this.poll();
      }
    },
  },

  created() {
    this.displayAuthorisationResponseDialog = false;
  },

  methods: {
    ...mapActions('serviceRequests', ['getServiceRequestStatus']),

    ...mapWaitingActions('serviceRequests', {
      getServiceRequestStatus: 'serviceRequestStatusLoading',
    }),

    async executePoll() {
      const result = await this.getServiceRequestStatus(this.serviceRequestNumber);
      this.attempts += 1;

      switch (result.status) {
        case 'Authorised':
          this.authorisationNumber = result.authorisationNumber;
          this.authorisationError = null;
          this.authorisationTitle = 'Authorisation Successful';

          return;
        case 'In Progress':
          if (this.maxAttempts && this.attempts === this.maxAttempts) {
            this.displayAuthorisationResponseDialog = false;
            this.$router.push({
              name: 'ElectronicAuthorisationsViewWorkAuthorisation',
              params: {
                serviceRequestNumber: this.serviceRequestNumber,
              },
            });
          }
          break;
        case '400':
          if (this.maxAttempts && this.attempts === this.maxAttempts) {
            this.displayAuthorisationResponseDialog = false;
            this.$router.push({
              name: 'ElectronicAuthorisationsViewWorkAuthorisation',
              params: {
                serviceRequestNumber: this.serviceRequestNumber,
              },
            });
          }
          break;
        default:
          // Errors
          this.authorisationNumber = null;
          this.authorisationError = result.statusMessage || 'Authorisation Failed, please go to the authorisations inbox for further detail.';
          this.authorisationTitle = 'Authorisation Failed';

          return;
      }

      if (this.maxAttempts && this.attempts === this.maxAttempts) {
        this.authorisationNumber = null;
        this.authorisationError = result.statusMessage;
        this.authorisationTitle = 'Authorisation In Progresing';

        throw new Error('Exceeded max attempts');
      }

      setTimeout(this.executePoll, this.interval);
    },

    async poll() {
      this.attempts = 0;
      return this.executePoll();
    },

    closeAuthDialog() {
      this.displayAuthorisationResponseDialog = false;
      this.$router.push({
        name: 'ElectronicAuthorisationsViewWorkAuthorisation',
        params: {
          serviceRequestNumber: this.serviceRequestNumber,
        },
      });
    },
  },
};
</script>
