<template>
  <sb-card :loading="loading"
           title="Merchant Details"
           min-width="100%">
    <sb-empty-message v-if="!merchantDetails.merchant.merchantNumber"
                      message="No Merchant Detail Available" />

    <v-container v-if="!loading && merchantDetails.merchant.merchantNumber">
      <v-row>
        <v-col :cols="hideServices ? 12 : 6">
          <v-row>
            <v-col cols="12"
                   :sm="hideServices ? 6 : undefined"
                   class="pt-0">
              <sb-input label="Merchant Number"
                        :value="merchantDetails.merchant.merchantNumber" />
            </v-col>
            <v-col cols="12"
                   :sm="hideServices ? 6 : undefined">
              <sb-input label="Merchant Name"
                        :value="merchantDetails.merchant.merchantName" />
            </v-col>
            <v-col cols="12"
                   :sm="hideServices ? 6 : undefined">
              <sb-input label="Contact Name"
                        :value="merchantDetails.merchant.contactDetails.name || 'Unknown'" />
            </v-col>
            <v-col cols="12"
                   :sm="hideServices ? 6 : undefined">
              <sb-input label="Contact Number"
                        :value="merchantDetails.merchant.contactDetails.phoneNumber || 'Unknown'" />
            </v-col>
            <v-col cols="12"
                   :sm="hideServices ? 6 : undefined">
              <sb-input label="Contact Email"
                        :value="merchantDetails.merchant.contactDetails.email || 'Unknown'" />
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="!hideServices"
               cols="6">
          <sb-input label="Registered Services">
            <v-list dense>
              <v-list-item v-for="(item, i) in merchantDetails.serviceOfferings"
                           :key="i"
                           class="px-0">
                <v-list-item-icon class="dense-list-icon">
                  <v-icon v-if="item.selected"
                          color="success">
                    mdi-check
                  </v-icon>
                  <v-icon v-else
                          color="error">
                    mdi-close
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.description" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </sb-input>
        </v-col>
      </v-row>
    </v-container>
  </sb-card>
</template>

<script>
export default {
  props: {
    merchantDetails: {
      type: Object,
      default: () => {},
    },
    hideServices: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .dense-list-icon {
    margin-right: 10px !important;
  }
</style>
