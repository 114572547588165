<template>
  <v-card hover
          :class="{'noclick': noClick}"
          @click="selectItem(item)">
    <v-row v-if="loading"
           justify="center">
      <sb-loader />
    </v-row>

    <v-list-item v-else
                 three-line>
      <v-list-item-content>
        <v-row>
          <v-col class="py-0"
                 cols="4">
            <v-list-item-title class="text-h4 font-weight-bold">
              <v-tooltip right>
                <template #activator="{on}">
                  <span v-on="on">
                    {{ item.serviceRequestNumber }}
                  </span>
                </template>
                <span>Service Request Number</span>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle class="text-caption">
              {{ item.serviceType.value }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>{{ item.createdTimestamp }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ }}</v-list-item-subtitle>
          </v-col>
          <v-col cols="1">
            <v-divider vertical />
          </v-col>
          <v-col v-if="item.authorisationDetail.authorisationMessage">
            <span class="text-subtitle-1 font-weight-bold">Status Reason:</span> <br>
            <span class="text-subtitle-2">{{ item.authorisationDetail.authorisationMessage }}</span>
          </v-col>
          <v-col v-if="item.authorisationDetail.authorisationNumber && item.authorisationDetail.authorisationNumber > 0">
            <span class="text-subtitle-1 font-weight-bold">Authorisation Number:</span> <br>
            <v-list-item-subtitle class="text-caption">
              {{ item.authorisationDetail.authorisationNumber }}
            </v-list-item-subtitle>
          </v-col>
          <v-col v-if="!item.authorisationDetail.authorisationNumber && !item.authorisationDetail.authorisationMessage">
            <span class="text-subtitle-1 font-weight-bold">Processing...</span> <br>
            <span class="text-subtitle-2">Pending...</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tooltip top>
              <template #activator="{on}">
                <div class="text-body-1 font-weight-bold"
                     v-on="on">
                  <v-icon left>
                    mdi-account
                  </v-icon>
                  {{ item.client.name }}
                </div>
              </template>
              <span>Client Name</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tooltip top>
              <template #activator="{on}">
                <div class="text-body-1 font-weight-bold"
                     v-on="on">
                  <v-icon left>
                    mdi-map-marker-distance
                  </v-icon>
                  {{ item.currentTask }}
                </div>
              </template>
              <span>Current Task</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item-content>
      <v-list-item-action>
        <v-row>
          <v-col class="py-0">
            <v-tooltip top>
              <template #activator="{on}">
                <v-chip label
                        dark
                        tag="div"
                        :color="getAuthStatusColor(item.authorisationDetail.authorisationstatus)"
                        v-on="on">
                  {{ item.authorisationDetail.authorisationstatus }}
                </v-chip>
              </template>
              <span>Authorisation Status</span>
            </v-tooltip>
          </v-col>
          <v-col class="py-0">
            <v-tooltip top>
              <template #activator="{on}">
                <v-chip label
                        dark
                        tag="div"
                        :color="getSeverityColor(item.severity)"
                        v-on="on">
                  {{ item.severity | startCase }}
                </v-chip>
              </template>
              <span>Severity</span>
            </v-tooltip>
          </v-col>
          <v-col v-if="showRefresh"
                 class="py-0">
            <v-tooltip top>
              <template #activator="{on}">
                <v-btn class="sb-mt-nn6 ml-0 sb-mr-nn6"
                       fab
                       dark
                       small
                       color="secondary"
                       v-on="on"
                       @click="refresh(item.serviceRequestNumber)">
                  <v-icon dark>
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <v-card-actions v-if="showDownload">
      <v-spacer />
      <v-btn color="secondary"
             :loading="isDownloading"
             @click="downloadAuthLetter(item)">
        Download Auth Letter
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { AUTH_STATUS_COLORS, SEVERITY_COLORS } from '@/config/constants';

export default {
  components: {},

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    noClick: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRefresh: {
      type: Boolean,
      default: false,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    isDownloading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  methods: {
    getAuthStatusColor(status) {
      return AUTH_STATUS_COLORS[status];
    },

    selectItem(item) {
      if (!this.noClick) {
        this.$router.push({
          name: 'ElectronicAuthorisationsViewWorkAuthorisation',
          params: {
            serviceRequestNumber: item.serviceRequestNumber,
          },
        });
      }
    },

    getSeverityColor(severity) {
      return SEVERITY_COLORS[severity];
    },

    refresh(serviceRequestNumber) {
      this.$emit('refresh-work-authorisation', serviceRequestNumber);
    },

    downloadAuthLetter(serviceRequestNumber) {
      this.$emit('download-work-authorisation-letter', serviceRequestNumber);
    },
  },
};
</script>
