<template>
  <div>
    <v-row v-if="$wait.is('getVehicleDetailsLoading')"
           justify="center"
           class="mt-5 pt-5">
      <sb-loader />
    </v-row>
    <v-row v-if="vehicleObject.vehicleResultVisible && !$wait.is('getVehicleDetailsLoading')"
           :justify="centered ? 'center' : undefined"
           no-gutters>
      <v-col cols="12">
        <div v-if="!readonly"
             class="text-caption mb-3">
          Vehicle Details
        </div>
        <v-row>
          <v-col cols="12"
                 sm="6">
            <sb-input label="Client ID"
                      :value="vehicleObject.vehicle.client.id" />
          </v-col>
          <v-col cols="12"
                 sm="6">
            <sb-input label="Client Name"
                      :value="vehicleObject.vehicle.client.name" />
          </v-col>
          <v-col cols="12"
                 sm="6">
            <sb-input label="Model Description"
                      :value="vehicleObject.vehicleModelDescription" />
          </v-col>

          <v-col cols="12"
                 sm="6">
            <sb-input label="Cost Centre"
                      :value="vehicleObject.vehicle.client.costCentre" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import VehicleDetails from '@/models/VehicleDetails';
import Vehicle from '../../models/Vehicle';

export default {
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    vehicleDetails: {
      type: Object,
      default: () => undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    registrationNumber: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      vehicleObject: {
        vehicleResultVisible: false,
      },
    };
  },

  watch: {
    vehicleDetails: {
      handler(value) {
        if (value) {
          this.vehicleObject.vehicle = value;
          const { year, make, model, registrationNumber } = this.vehicleObject.vehicle;
          this.vehicleObject.vehicleModelDescription = `${year} ${make} ${model} (${registrationNumber})`;
          this.vehicleObject.vehicleResultVisible = value.registrationNumber !== 'Pending';
        } else {
          this.vehicleObject.vehicleResultVisible = false;
        }
      },
      immediate: true,
    },
    registrationNumber: {
      handler(value) {
        this.updateVehicleResult(value);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('vehicles', ['getVehicleDetails']),

    ...mapWaitingActions('vehicles', {
      getVehicleDetails: 'getVehicleDetailsLoading',
    }),

    async updateVehicleResult(regNumber) {
      if (regNumber) {
        this.vehicleObject.vehicle = await this.getVehicleDetails(regNumber);
        this.vehicleObject.vehicleDetails = new VehicleDetails(this.vehicleObject.vehicle);
        this.vehicleObject.customer = this.vehicleObject.vehicle.client;

        const { year, make, model, registrationNumber } = this.vehicleObject.vehicle;
        this.vehicleObject.vehicleModelDescription = `${year} ${make} ${model} (${registrationNumber})`;

        this.vehicleObject.vehicleResultVisible = true;
      } else {
        this.vehicleObject.vehicleResultVisible = false;
        this.vehicleObject.vehicle = new Vehicle();
        this.vehicleObject.vehicleDetails = new VehicleDetails();
        this.vehicleObject.customer = this.vehicleObject.vehicle.client;
      }
      this.vehicleObject.vehicleDetails.odometerReading = Number(this.vehicleObject.vehicleDetails.odometerReading);
      this.$emit('vehicle-update', this.vehicleObject);
    },
  },
};
</script>
