<template>
  <v-row>
    <v-col class="d-flex align-stretch pb-0">
      <sb-card :title="title"
               :loading="serviceRequestLoading"
               min-width="100%">
        <v-container>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <sb-text-field :value="serviceRequest.driverDetails.fullname"
                             :readonly="readOnly && !canUpdateAuthorisation"
                             rules="required"
                             label="Driver Name"
                             @input-with-label="fieldUpdated" />
            </v-col>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <sb-text-field :value="serviceRequest.driverDetails.contactDetails.phoneNumber"
                             :readonly="readOnly && !canUpdateAuthorisation"
                             rules="required"
                             label="Driver Contact Number"
                             @input-with-label="fieldUpdated" />
            </v-col>
          </v-row>
        </v-container>
      </sb-card>
    </v-col>
  </v-row>
</template>

<script>
export default {

  props: {
    title: {
      type: String,
      default: '',
    },
    serviceRequestLoading: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    canUpdateAuthorisation: {
      type: Boolean,
      default: false,
    },
    serviceRequest: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    fieldUpdated(data) {
      this.$emit('driver-update', data);
    },
  },
};
</script>
