<template>
  <div>
    <v-row v-if="isloading"
           justify="center">
      <sb-loader />
    </v-row>
    <sb-empty-message v-if="attachments.length === 0"
                      message="No documents found." />

    <v-list two-line>
      <template v-for="(item, index) in items">
        <v-divider v-if="index !== 0 && index !== items.length"
                   :key="`divider-${index}`"
                   inset />
        <v-list-group :key="item.fileType"
                      no-action
                      color="primary">
          <template #activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.fileType" />
            </v-list-item-content>
          </template>

          <v-list-item v-for="(child, childIndex) in item.files"
                       :key="child.id"
                       @click="downloadDocument(child, childIndex)">
            <v-list-item-avatar tile>
              <v-img :src="`/img/file-icons/${getFileIcon(child.filename)}`" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="child.filename" />
              <v-list-item-subtitle v-text="child.createdDate" />
            </v-list-item-content>
            <v-list-item-action :key="`action-${index}`">
              <span class="text-body-1">
                {{ filesize(child.contentLength, {base: 10}) }}
              </span>
            </v-list-item-action>
            <v-list-item-avatar>
              <sb-loader v-if="loadSpinner(childIndex)"
                         :size="20" />
            </v-list-item-avatar>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </div>
</template>
<script>
import { FILE_ICONS, REFERENCE_LISTS } from '@/config/constants';
import filesize from 'filesize';
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

export default {
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    isloading: {
      type: Boolean,
      default: false,
    },
    isDownloading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filesize,
      selectedIndex: 0,
      items: [],
      fileNetDocTypes: [],
    };
  },
  watch: {
    attachments: {
      async handler(value) {
        if (value.length > 0) {
          const refListFileNetDocTypes = await this.getReferenceList(REFERENCE_LISTS.FILENET_DOCUMENT_TYPES);
          this.fileNetDocTypes = refListFileNetDocTypes || [];
          this.items = this.groupAttachmentList(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('referenceLists', ['getReferenceList']),
    ...mapWaitingActions('referenceLists', {
      getReferenceList: 'referenceListLoading',
    }),
    getFileIcon(filename) {
      let fileIcon = FILE_ICONS.unknown;
      const extention = filename.split('.').pop();
      const cleanExtention = extention.split(' ')[0].toLowerCase();

      if (Object.keys(FILE_ICONS).includes(cleanExtention)) {
        fileIcon = FILE_ICONS[cleanExtention];
      }

      return fileIcon;
    },

    downloadDocument(item, index) {
      this.selectedIndex = index;
      this.$emit('download-document', item.id);
    },

    loadSpinner(index) {
      return this.selectedIndex === index && this.isDownloading;
    },

    groupAttachmentList(objectArray) {
      const results = [];
      objectArray.forEach((file) => {
        /* eslint-disable */
        const fileType = this.getFileNetName(file.fileType);
        if (results.some((data) => data.fileType === fileType)) {
          results.some((obj) => {
            if (obj.fileType === fileType) {
              obj.files.push({
                id: file.id,
                contentType: file.contentType,
                filename: file.filename,
                createdDate: file.createdDate,
                contentLength: file.contentLength,
              });
            }
          });
        } else {
          results.push({
            fileType: fileType,
            files: [
              {
                id: file.id,
                contentType: file.contentType,
                filename: file.filename,
                createdDate: file.createdDate,
                contentLength: file.contentLength,
              },
            ],
          });
        }
      });

      return results;
    },

    getFileNetName(fileType) {
      const fileNetName = this.fileNetDocTypes.find((name) => name.key === fileType);
      return fileNetName.value;
    },
  },
};
</script>
