<template>
  <ValidationObserver ref="obs"
                      v-slot="{invalid, passes}">
    <ValidationProvider v-if="!disabled"
                        v-slot="{classes}">
      <sb-input :error="classes.invalid"
                class="">
        <v-row v-if="!hideFileNetFileType">
          <v-col cols="6">
            <sb-select v-model="selectedFileNetDocType"
                       :items="fileNetDocTypes"
                       item-text="value"
                       item-value="key"
                       label="Document Type" />
          </v-col>
        </v-row>
        <v-row align="start">
          <v-col>
            <sb-file-upload-field v-model="files"
                                  rules="required|document"
                                  :file-types="'image/*,.pdf'"
                                  :is-multiple-file-selection="true"
                                  show-file-counter
                                  label="Attachments" />
          </v-col>

          <v-col cols="auto">
            <v-btn :loading="$wait.is('uploadAttachmentsLoading')"
                   :disabled="invalid"
                   x-large
                   depressed
                   color="secondary"
                   @click="passes(uploadSelectedAttachments)">
              Upload
              <v-icon right>
                mdi-cloud-upload
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </sb-input>
    </ValidationProvider>
  </ValidationObserver>
</template>
<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { REFERENCE_LISTS } from '@/config/constants';

extend('document', {
  validate(value) {
    return !value.some((file) => file.size > 5_242_880);
  },
  message: 'Document size should be less than 5 MB!',
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultFileNetFileType: {
      type: String,
      default: '',
    },
    hideFileNetFileType: {
      type: Boolean,
      default: false,
    },
    serviceRequestId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      files: [],
      fileTypes: 'image/*,.pdf',
      fileNetDocTypes: [],
      selectedFileNetDocType: this.defaultFileNetFileType,
    };
  },
  async created() {
    if (!this.hideFileNetFileType) {
      const refListFileNetDocTypes = await this.getReferenceList(REFERENCE_LISTS.FILENET_DOCUMENT_TYPES);
      this.fileNetDocTypes = refListFileNetDocTypes || [];
    }
  },
  methods: {
    ...mapActions('attachments', ['uploadAttachments']),
    ...mapActions('referenceLists', ['getReferenceList']),

    ...mapWaitingActions('attachments', {
      uploadAttachments: 'uploadAttachmentsLoading',
    }),
    ...mapWaitingActions('referenceLists', {
      getReferenceList: 'referenceListLoading',
    }),

    async uploadSelectedAttachments() {
      try {
        const payload = {
          files: this.files,
          fileType: this.selectedFileNetDocType,
          serviceRequestId: this.serviceRequestId,
        };
        await this.uploadAttachments(payload);
        this.files = [];
      } catch (error) {
        this.$notify.error(error);
      }
    },
  },
};
</script>
