<template>
  <v-row>
    <v-col>
      <sb-card :title="title"
               :loading="loading"
               min-width="100%">
        <v-container>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <sb-text-field :value="jobCard.jobNumber"
                             :readonly="readOnly && !canUpdateAuthorisation"
                             rules="required"
                             label="Job Number"
                             @input-with-label="onInputWithLabel" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <sb-text-field :value="jobCard.preAuthorisationNumber"
                             :readonly="readOnly && !canUpdateAuthorisation"
                             label="Pre Authorisation Number"
                             @input-with-label="onInputWithLabel" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="3"
                   class="py-0 mb-5">
              <sb-date-picker-field :value="jobCard.appointmentDate"
                                    :disabled="readOnly && !canUpdateAuthorisation"
                                    label="Appointment Date"
                                    rules="required"
                                    ref-name="appointmentDate"
                                    @input-with-label="onInputWithLabel" />
            </v-col>
            <v-col cols="12"
                   sm="3"
                   class="py-0 mb-5">
              <sb-time-picker :value="jobCard.appointmentTime"
                              :disabled="readOnly && !canUpdateAuthorisation"
                              rules="required"
                              label="Appointment Time"
                              ref-name="appointmentTime"
                              @input-with-label="onInputWithLabel" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12"
                   sm="3"
                   class="py-0 mb-5">
              <sb-date-picker-field :value="jobCard.estimatedCompletionDate"
                                    :disabled="readOnly && !canUpdateAuthorisation || estimateFieldsDisabled"
                                    :min="jobCard.appointmentDate"
                                    label=" Estimated Completion Date"
                                    ref-name="completionDate"
                                    @input-with-label="onInputWithLabel" />
            </v-col>
            <v-col cols="12"
                   sm="3"
                   class="py-0 mb-5">
              <sb-time-picker :value="jobCard.estimatedCompletionTime"
                              :disabled="readOnly && !canUpdateAuthorisation || estimateFieldsDisabled"
                              :min="minEstimatedCompletionTime"
                              ref-name="estimatedCompletionTime"
                              label="Estimated Completion Time"
                              @input-with-label="onInputWithLabel" />
            </v-col>
          </v-row>

          <v-row v-if="!readOnly || canUpdateAuthorisation">
            <v-col cols="12"
                   sm="6"
                   class="py-0 mb-5">
              <sb-select :value="jobCard.workRequiredSelection"
                         :items="filteredAuthWorkRequiredItems"
                         :readonly="readOnly && !canUpdateAuthorisation"
                         label="Work to be done"
                         item-value="key"
                         item-text="value"
                         return-object
                         rules="selected|required"
                         multiple
                         @input-with-label="onInputWithLabel" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <sb-text-area :value="jobCardComments"
                            rules="required|textArea"
                            :readonly="readOnly && !canUpdateAuthorisation"
                            label="Comments"
                            ref-name="Comments"
                            @keydown.space="preventLeadingSpace"
                            @input-with-label="onInputWithLabel" />
            </v-col>
          </v-row>
          <sb-empty-message v-if="jobCard.workRequiredSelection.length === 0"
                            message="No Parts to display."
                            sub-message="Start by selecting Work to be done above." />

          <template v-else>
            <v-row>
              <v-col cols="12"
                     class="py-0" />

              <v-expansion-panels multiple>
                <template v-for="(section, index) in jobCard.workRequiredSelection">
                  <v-expansion-panel :key="index">
                    <v-expansion-panel-header>
                      <template #default="{open}">
                        <v-row no-gutters>
                          <v-col cols="4">
                            {{ section.value }}
                          </v-col>
                          <v-col cols="8"
                                 class="text--secondary">
                            <v-fade-transition leave-absolute>
                              <span v-if="open"
                                    key="0">
                                {{ section.description }}
                              </span>
                            </v-fade-transition>
                          </v-col>
                        </v-row>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="px-0">
                      <v-data-table :headers="AUTH_TABLE_HEADERS[section.key]"
                                    :items="jobCard.sections[section.key]"
                                    :items-per-page="jobCard.sections[section.key].length"
                                    hide-default-footer>
                        <template v-if="!readOnly || canUpdateAuthorisation"
                                  #item.partNo="{item}">
                          <sb-job-card-item-search :readonly="readOnly && !canUpdateAuthorisation"
                                                   :merchant-id="merchantId"
                                                   :item-type="section.key"
                                                   :reference="{index: item.index, sectionKey: section.key}"
                                                   :current-value="item.partNo"
                                                   @item-update="updateJobCardItem"
                                                   @item-clear="clearJobCardItem" />
                        </template>
                        <template v-if="!readOnly || canUpdateAuthorisation"
                                  #item.qty="{item}">
                          <sb-numeric-field v-model.number="item.qty"
                                            :min="0"
                                            :max="100"
                                            hide-details />
                        </template>
                        <template v-if="section.key === 'other' && (!readOnly || canUpdateAuthorisation)"
                                  #item.retailPriceExclVat="{item}">
                          <sb-currency-field v-model="item.retailPriceExclVat"
                                             hide-details />
                        </template>
                        <template v-else
                                  #item.retailPriceExclVat="{item}">
                          {{ item.retailPriceExclVat | currency }}
                        </template>

                        <template #item.unitPriceExclVat="{item}">
                          {{ item.unitPriceExclVat | currency }}
                        </template>
                        <template v-if="section.key !== 'other' && (!readOnly || canUpdateAuthorisation)"
                                  #item.discountPercent="{item}">
                          <sb-numeric-field v-model="item.discountPercent"
                                            :max="100"
                                            :float="true"
                                            :percent="true"
                                            hide-details
                                            @change="updateDiscountPercent(item, section.key)" />
                        </template>
                        <template v-else
                                  #item.discountPercent="{item}">
                          {{ item.discountPercent }}%
                        </template>
                        <template v-if="section.key !== 'other' && (!readOnly || canUpdateAuthorisation)"
                                  #item.specialPriceExclVat="{item}">
                          <sb-currency-field v-model="item.specialPriceExclVat"
                                             :max="parseFloat(item.retailPriceExclVat)"
                                             :float="true"
                                             hide-details
                                             @change="updateSpecialPrice(item, section.key)" />
                        </template>
                        <template v-else
                                  #item.specialPriceExclVat="{item}">
                          {{ item.specialPriceExclVat | currency }}
                        </template>
                        <template #item.totalNetAmount="{item}">
                          {{ item.totalNetAmount | currency }}
                        </template>

                        <template #item.actions="{item}">
                          <v-tooltip left>
                            <template #activator="{on, attrs}">
                              <v-icon :disabled="readOnly && !canUpdateAuthorisation"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="deleteItem(item, section.key)">
                                mdi-delete
                              </v-icon>
                            </template>
                            <span>Delete Item</span>
                          </v-tooltip>
                        </template>

                        <template #footer>
                          <v-row>
                            <v-col>
                              <v-btn v-if="!readOnly || canUpdateAuthorisation"
                                     color="secondary"
                                     class="float-right"
                                     depressed
                                     :loading="createAuthorisationLoading"
                                     :disabled="disabled"
                                     @click="addItem(section.key)">
                                <v-icon left>
                                  mdi-plus
                                </v-icon>
                                <span>Add Item</span>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </v-row>

            <v-row>
              <v-col cols="12">
                <sb-work-auth-totals :totals="totals" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"
                     sm="6"
                     class="py-0">
                <sb-text-field :value="jobCard.serviceAdvisorName"
                               :readonly="readOnly && !canUpdateAuthorisation"
                               rules="required"
                               label="Service Adviser Name"
                               @input-with-label="onInputWithLabel" />
              </v-col>
              <v-col cols="12"
                     sm="6"
                     class="py-0">
                <sb-text-field :value="jobCard.serviceAdvisorContactNumber"
                               :readonly="readOnly && !canUpdateAuthorisation"
                               rules="required"
                               label="Service Adviser Contact Number"
                               @input-with-label="onInputWithLabel" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     sm="6"
                     class="py-0">
                <sb-text-field :value="jobCard.authorisationLetterEmail"
                               :readonly="readOnly && !canUpdateAuthorisation"
                               rules="required|email"
                               label="Authorisation Letter Email Address"
                               @input-with-label="onInputWithLabel" />
              </v-col>
            </v-row>
          </template>
        </v-container>
      </sb-card>
    </v-col>
  </v-row>
</template>

<script>
import { AUTH_TABLE_HEADERS } from '@/config/constants';

import SbJobCardItemSearch from '@/components/authorisation/SbJobCardItemSearch';
import SbWorkAuthTotals from '@/components/authorisation/SbWorkAuthTotals';
import { extend } from 'vee-validate';

let errorMessage = '';
extend('textArea', {
  validate(value) {
    if (value && value.length < 20) {
      errorMessage = 'must be greater than 20 characters';
    } else {
      return true;
    }
    return false;
  },
  message: (field) => `${field} ${errorMessage}`,
});

export default {
  components: {
    SbJobCardItemSearch,
    SbWorkAuthTotals,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    jobCard: {
      type: Object,
      default: () => {},
    },
    totals: {
      type: Object,
      default: () => {},
    },
    canUpdateAuthorisation: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    createAuthorisationLoading: {
      type: Boolean,
      default: false,
    },
    estimateFieldsDisabled: {
      type: Boolean,
      default: false,
    },
    merchantId: {
      type: String,
      default: '',
    },
    filteredAuthWorkRequiredItems: undefined,
    minEstimatedCompletionTime: undefined,
  },

  data() {
    return {
      AUTH_TABLE_HEADERS,
      jobCardComments: '',
    };
  },
  watch: {
    'jobCard.jobComments': {
      handler(value) {
        this.jobCardComments = value;
      },
    },
  },
  methods: {
    addItem(section) {
      this.$emit('add-item', section);
    },
    deleteItem(item, section) {
      this.$emit('delete-item', { item, section });
    },
    updateJobCardItem(item) {
      this.$emit('item-update', item);
    },
    updateDiscountPercent(item, section) {
      this.$emit('discount-update-percent', item, section, 'discount');
    },
    updateSpecialPrice(item, section) {
      this.$emit('special-price-update', item, section, 'special-price');
    },
    clearJobCardItem(item) {
      this.$emit('item-clear', item);
    },
    onInputWithLabel(value) {
      this.$emit('job-update', value);
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      this.jobCardComments = e.target.value.replace(/\s+/g, ' ');
    },
  },
};
</script>
