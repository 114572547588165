<template>
  <v-dialog v-model="displayReasonDialog"
            scrollable
            max-width="450"
            persistent>
    <sb-card title="Decline Reason">
      <v-card-text style="height: 400px;">
        <template v-for="(item, index) in declineReasons">
          <v-checkbox :key="index"
                      v-model="selectedReasons"
                      color="secondary"
                      hide-details
                      on-icon="mdi-checkbox-intermediate"
                      :label="item.value"
                      :value="item.key" />
        </template>
      </v-card-text>

      <template slot="actions">
        <v-spacer />
        <v-btn color="secondary"
               outlined
               @click="cancel">
          Cancel
        </v-btn>
        <v-btn color="error"
               depressed
               :disabled="$wait.any"
               @click="decline">
          Decline
        </v-btn>
      </template>
    </sb-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    declineReasons: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedReasons: [],
    };
  },

  computed: {
    displayReasonDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    cancel() {
      this.displayReasonDialog = false;
      this.selectedReasons = [];
    },

    decline() {
      this.displayReasonDialog = false;
      this.$emit('declined', this.selectedReasons);
    },
  },
};
</script>
