<template>
  <sb-card v-if="canSearch"
           :title="title"
           :loading="isLoading"
           min-width="100%">
    <v-container>
      <sb-merchant-search centered
                          @merchant-update="merchantUpdate" />
    </v-container>
  </sb-card>

  <sb-merchant-detail v-else
                      :loading="isLoadingMerchant"
                      :merchant-details="merchantDetails" />
</template>

<script>
import SbMerchantDetail from '@/components/merchant/SbMerchantDetail';
import SbMerchantSearch from '@/components/merchant/SbMerchantSearch';

export default {
  components: {
    SbMerchantDetail,
    SbMerchantSearch,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingMerchant: {
      type: Boolean,
      default: false,
    },
    canSearch: {
      type: Boolean,
      default: false,
    },
    merchantDetails: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    merchantUpdate(merchant) {
      this.$emit('merchant-update', merchant);
    },
  },

};
</script>
